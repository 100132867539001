'use strict'

angular
	.module('theme.messages-controller', [])
	.controller('MessagesController', ["$rootScope", "$location", "$interval", "$scope", "$modal", "MessagesService", "NotificationService", "IpadService", function ($rootScope, $location, $interval, $scope, $modal, MessagesService, NotificationService, IpadService) {
		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};
		$rootScope.$on("UpdateProformaInfo", function () {
			$scope.getProformaAlerts();
		});

		$scope.$on('UserPopulated', () => {
			$scope.init();
		});

		$scope.$on('UpdateKPIs', () => {
			$scope.init();
		});

		$scope.$on('GetHistory', () => {
			//getUserHistoryItems();
		});

		$scope.$on('appointmentRead', function () {
			$interval.cancel($scope.timer);
			$scope.init();
		});

		$scope.init = function () {
			$scope.viewFollowUpsPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Desk' || r.Name == 'Marketing' || r.Name == 'Partnership').length > 0 ? true : false;
			$scope.alertCount = 0;
			$scope.reminderCount = 0;

			// Proforma. 07/07/2022. Proforma jobs must be flagged if not invoiced 1 week before installation.
			$scope.proforma = 0;
			$scope.proformref = '#';
			
			$scope.showProforma = function () {	
				if ($scope.proformref.length != 0) {
					openModal('views/modal-templates/proforma-list.html', 'ProformaSearchController', $scope.proformref);
				}		 
			}

			$scope.getProformaAlerts();
			// Proforma end.

			$scope.lettersCount = 0;
			$scope.callsCount = 0;
			$scope.userHistoryItemsCount = 0;
			$scope.getAlerts();
			getReminders();
			getFollowUpLetters();
			//getUserHistoryItems();

			$scope.timer = $interval(function () {
				$scope.init()
			}, 300000, 1, false);
		};

		// Proforma. 07/07/2022. Progorma jobs must be flagged if not invoiced 1 week before installation.
		$scope.getProformaAlerts = function () {

			if ($rootScope.onLineFlag) {
				$scope.getProformaPromise = MessagesService.GetProformaAlerts();
				$scope.getProformaPromise.then(data => {

					$scope.proforma = data.data.ProformAlerts.Invoices;
					$scope.proformref = data.data.ProformAlerts.Ref;

					var isAdmin = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator').length > 0;
					var isAccounts = $rootScope.User.Roles.filter(r => r.Name == 'Accounts').length > 0;
					$scope.proformaVisible = (isAdmin || isAccounts) && $scope.proforma > 0;

				}, err => {
					NotificationService.alert('Get Alerts Error', 'There was an error retrieving your proforma alerts.', 'error', {});
				});
			}
		};

		// Proforma end.

		$scope.getAlerts = function () {
			if ($rootScope.onLineFlag) {
				$scope.getAlertsPromise = MessagesService.GetAlertMetrics({ Pagination: { StartPage: 0, PageSize: 99999 }, SalesPersonId: $rootScope.User.Id });
				$scope.getAlertsPromise.then(data => {
					$scope.alertCount = data.data.Alerts ? data.data.Alerts.length : 0;
				}, err => {
					NotificationService.alert('Get Alerts Error', 'There was an error retrieving your alerts.', 'error', {});
				});
			} else {
				const data = IpadService.getAlertMertics()
				data.then(data => {
					$scope.alertCount = data ? data.length : 0;
				})
			}
		};

		function getReminders() {
			if ($rootScope.onLineFlag) {
				$scope.getRemindersPromise = MessagesService.GetReminderMetricsCount({ Pagination: { StartPage: 0, PageSize: 99999 }, SalesPersonId: $rootScope.User.Id });
				$scope.getRemindersPromise.then(data => {
					$scope.reminderCount = data.data.FollowUpCount;
				}, err => {
					NotificationService.alert('Get Reminders Error', 'There was an error retrieving your reminders.', 'error', {});
				});
			} else {
				const data = IpadService.getRemindersMertics()
				data.then(data => {
					$scope.reminderCount = data ? data.length : 0;
				})
			}
		};

		function getFollowUpLetters() {
			if (!$scope.viewFollowUpsPermission)
				return;
			$scope.getFollowUpLettersPromise = MessagesService.GetFollowUpMetricsCount();
			$scope.getFollowUpLettersPromise.then(data => {
				$scope.lettersCount = data.data.FollowUpCount;
				// getFollowUpCalls();
			}, err => {
				NotificationService.alert('Get Follow-up Letters Error', 'There was an error retrieving your follow-up letters.', 'error', {});
			});
		};

		// function getFollowUpCalls() {
		// 	if (!$scope.viewFollowUpsPermission)
		// 		return;
		// 	$scope.getFollowUpCallsPromise = MessagesService.GetFollowUpMetrics({ Pagination: { StartPage: 0, PageSize: 99999 }, Type: 'Calls' });
		// 	$scope.getFollowUpCallsPromise.then(data => {
		// 		$scope.callsCount = data.data.Leads ? data.data.Leads.length : 0;
		// 	}, err => {
		// 		NotificationService.alert('Get Follow-up Calls Error', 'There was an error retrieving your follow-up calls.', 'error', {});
		// 	});
		// };

		function getUserHistoryItems() {
			//UNCOMMENT WHEN HISTORY FIXED
			//$scope.getUserHistoryItemsPromise = MessagesService.GetUserHistoryItems();
			//$scope.getUserHistoryItemsPromise.then(function(data) {
			//	$scope.userHistoryItems = data.data;
			//	$scope.userHistoryItemsCount = data.data.length;
			//}, function(error) {
			//	NotificationService.alert('Get User History Error', 'There was an error retrieving your history.', 'error', {});
			//})
		}

		$scope.openKPI = function (type) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				backdrop: 'static',
				templateUrl: 'views/modal-templates/view-kpi.html',
				controller: 'KPIModalController',
				resolve: {
					Type: () => {
						return type;
					}
				}
			});
			modalInstance.result.then(function (data) {
				if (data.Type == 'Alerts')
					$scope.getAlerts();
			});
		};
	}])

	.controller('KPIModalController', ["$rootScope", "$route", "$scope", "$modalInstance", "$modal", "NotificationService", "MessagesService", "Type", "$location", "$timeout", function ($rootScope, $route, $scope, $modalInstance, $modal, NotificationService, MessagesService, Type, $location, $timeout) {
		$scope.Pagination;
		$scope.Type = Type;
		$scope.NameFilter;
		$scope.timeout = $timeout(function () { });
		$scope.KPIListPageNumber = 1;
		$scope.KPIItemsPerPage = 15;

		$scope.$watch('KPIListPageNumber', function () {
			$scope.listKPIItems($scope.Type);
		});

		$scope.init = function () {
			$scope.list = [];
			$scope.mailingList = [];
			$rootScope.followUpLetterIds = [];
			$scope.TotalNumOfLetters = 0;
		};

		$scope.listKPIItems = function (type) {
			var data = {
				Pagination: {
					StartPage: $scope.KPIListPageNumber - 1,
					PageSize: $scope.KPIItemsPerPage
				},
				SalesPersonId: $rootScope.User.Id
			};

			if ($scope.NameFilter)
				data.Pagination.Filter = $scope.NameFilter;

			switch (type) {
				case 'Alerts':
					$scope.listingKPIItemsPromise = MessagesService.GetAlertMetrics(data);
					break;
				case 'Reminders':
					$scope.listingKPIItemsPromise = MessagesService.GetReminderMetrics(data);
					break;
				default:
					data.Type = $scope.Type;
					$scope.listingKPIItemsPromise = MessagesService.GetFollowUpMetrics(data);
					break;
			};

			$scope.listingKPIItemsPromise.then(function (output) {
				$scope.list = output.data.Leads || output.data.Alerts || output.data.Reminders;
				if (type == "Letters")
					$scope.TotalNumOfLetters = output.data.LeadCount;
				$scope.Pagination = output.data.Pagination;
				if ($scope.mailingList.length > 0) {
					for (var i = 0; i < $scope.list.length; i++) {
						if ($scope.mailingList.includes($scope.list[i].Id))
							$scope.list[i].SetToMail = true;
						else
							$scope.list[i].SetToMail = false;
					}
				}
			}, function (error) {
				NotificationService.alert('List Items', 'There was an error retrieving the list of items for this type.', 'error', {});
			});
		};

		$scope.filterItems = function () {
			$timeout.cancel($scope.timeout);
			$scope.timeout = $timeout(() => {
				$scope.KPIListPageNumber = 1;
				$scope.listKPIItems($scope.Type);
			}, 500);
		};

		$scope.open = function (id, route) {
			if (id instanceof Object) {
				id.ReplyingToReminder = true;
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					backdrop: 'static',
					templateUrl: 'views/modal-templates/add-memo.html',
					controller: 'SendMemoController',
					resolve: {
						data: () => {
							return id;
						}
					}
				});
				modalInstance.result.then(function (data) {
					if (data.Type == 'Alerts')
						$scope.getAlerts();
				});
			} else {
				var url = '/' + route + '/' + id;
			}
			$location.url(url);
			$modalInstance.dismiss();
		};

		$scope.markAlertDealt = function (id) {
			$scope.markingAlertAsDealtPromise = MessagesService.MarkAlertAsDealt({ AlertId: id });
			$scope.markingAlertAsDealtPromise.then(success => {
				NotificationService.alert('Alert Closed', 'Alert successfully dealt with.', 'success', {});
				$scope.listKPIItems($scope.Type);
			}, err => {
				NotificationService.alert('Alert Error', 'Unable to mark this alert as dealt with, please try again.', 'error', {});
			});
		};

		$scope.deleteAlert = function (id) {
			$scope.deletingAlertPromise = MessagesService.DeleteAlert({ AlertId: id });
			$scope.deletingAlertPromise.then(success => {
				NotificationService.alert('Alert Deleted', 'Alert successfully deleted.', 'success', {});
				$scope.listKPIItems($scope.Type);
			}, err => {
				NotificationService.alert('Alert Error', 'Unable to delete this alert, please try again.', 'error', {});
			});
		};

		$scope.setForMailing = function (item) {
			if (item.SetToMail == true) {
				$scope.mailingList.push(item.Id);
			}
			else {
				var index = $scope.mailingList.indexOf(item.Id);
				if (index >= 0)
					$scope.mailingList.splice(index, 1)
			}
		}

		$scope.CreateMailing = function () {
			$rootScope.followUpLetterIds = $scope.mailingList;
			var url = '/follow-up-letters'
			$location.path(url);
			$route.reload();
			$modalInstance.dismiss();
		}

		$scope.close = function () {
			$modalInstance.close({ Type: $scope.Type });
		};

		$scope.FetchLastPage = function () {
			if ($scope.TotalNumOfLetters > $scope.KPIItemsPerPage) {
				var LastPageNum = $scope.TotalNumOfLetters / $scope.KPIItemsPerPage;
				$scope.KPIListPageNumber = Math.ceil(LastPageNum);
				$scope.listKPIItems();
			}
		}
	}])
